<template>
  <div :class="$style.root">
    <div class="mb-2">
      <h1 v-if="$route.meta.title" class="mb-2">{{$route.meta.title}}</h1>
      <span v-if="pkoList.additional.title">{{ pkoList.additional.title }}</span>
      <VSkeletonLoader v-else-if="loading" type="text" class="my-2 p-1" />
    </div>
    <div class="mb-2 clearfix">
      <template v-if="!isEvrazEmployee">
        <VAlert v-if="!isProfileValid && !loading" class="my-1 py-3" type="error">В Профиле не заполнены обязательные поля. Пожалуйста, заполните Профиль</VAlert>
        <VSkeletonLoader v-else-if="loading" type="paragraph" class="my-2 p-1" />
      </template>
      <VAlert v-for="(message, index) in pkoList.additional.messages" :key="index" class="my-1 py-3" type="warning"><span v-html="message"/></VAlert>
      <VSkeletonLoader v-if="loading" type="paragraph" class="my-2 p-1" />
    </div>
    <div class="mb-2">
      <VRow dense>
        <template v-if="loading">
          <VCol cols="6" v-for="(index) in 4" :key="index">
            <VSkeletonLoader type="list-item-avatar, paragraph@3, actions" elevation="2" class="p-2" min-height="308" />
          </VCol>
        </template>
        <template v-else>
          <VCol cols="6" v-for="(item, index) in pkoList.items" :key="index">
            <PkoCard v-bind="{...item}" />
          </VCol>
        </template>
      </VRow>
    </div>
  </div>
</template>

<script>
import PkoCard from '@/views/pko/PkoDashboardView/components/PkoCard.vue';
import {mapActions, mapGetters} from 'vuex';
import {get, every, toString, omit, concat} from 'lodash-es';
export default {
  name: 'PkoDashboardView',
  components: {
    PkoCard,
  },
  computed: {
    ...mapGetters({
      loading: 'pko/pending',
      pkoList: 'pko/pkoList',
      user: 'user/profile',
    }),
    // Сотрудник Evraz
    isEvrazEmployee() {
      return get(this.user, 'isEvrazEmployee', false) || (toString(get(this.user, ['values', 'UF_USER_TYPE', 'values', 0])) === '7');
    },
    // Признак частного лица
    isPerson() {
      return !get(this.user, ['values', 'UF_USER_TYPE', 'values', 0]);
    },
    // Признак иностранной компании
    isForeign() {
      return toString(get(this.user, ['values', 'UF_USER_TYPE', 'values', 0])) === '18';
    },
    // Признак компании или ИП
    isCompany() {
      return toString(get(this.user, ['values', 'UF_USER_TYPE', 'values', 0])) === '6';
    },
    // Признак ИП
    isEntrepreneur() {
      return this.isCompany && (toString(this.inn).length === 12);
    },
    isProfileValid() {
      let fields = [];
      if (!this.isEvrazEmployee) {
        let excludeFields = [];
        if (this.isPerson) excludeFields = concat(excludeFields, ['UF_FULLNAME', 'UF_SHORTNAME', 'UF_TAX_ID', 'UF_KPP']);
        if (this.isCompany) excludeFields = concat(excludeFields, ['UF_PASSPORT_NUMBER', 'UF_PASSPORT_DATE', 'UF_OGRN', 'UF_TAX_ID', 'UF_KPP']);
        if (this.isForeign) excludeFields = concat(excludeFields, ['UF_PASSPORT_NUMBER', 'UF_PASSPORT_DATE', 'UF_OGRN']);
        fields = omit(get(this.user, 'values', {}), excludeFields);
      }
      return every(fields, (field) => {
        if (!get(field, 'rules')) return true;
        return every(
            get(field, 'values'),
            (value) => every(
                get(field, 'rules'),
                (rule) => {
                  if (get(rule, 'callback')) {
                    return rule.callback(value);
                  } else {
                    return (new RegExp(rule.reg)).test(value);
                  }
                }
            )
        );
      });
    },
  },
  methods: {
    ...mapActions({
      fetchPkoList: 'pko/fetchPkoList',
      fetchUserProfile: 'user/fetchProfileDetail',
    }),
  },
  mounted() {
    this.fetchPkoList();
    this.fetchUserProfile();
  },
}
</script>

<style module lang="scss">
.root {}
</style>